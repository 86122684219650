.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modal-wrapper {
  position: fixed;
  top: 10%;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  @media screen and (max-width: 400px) {
    height: 70%;
  }
}

.modal {
  font-family: 'Roboto', sans-serif;
  z-index: 100;
  background-color: #fcfceb;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 400px;
  padding: 1rem;
  text-align: center;
  color:#0c1547;

  @media screen and (max-width: 400px) {
    max-width: 280px;
  }
}

.modal > p {
  font-size: 14px;
  margin-bottom: 15px;
}

.modal .modal-span {
  font-size: 14.5px;
  font-weight: 900;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-family: 'Roboto', sans-serif;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
}

.div-button-modal {
  font-family: inherit;
  font-size: 18px;
  color: white;
  background-color:#0c1547;
  border-radius: 20px;
  width: fit-content;
  padding: 10px 30px;
  text-align: center;
  display: flex;
  align-items: center;
}



