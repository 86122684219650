#page-inicio {
  min-height: 100%;
  background-color: #62b997;
}

#page-inicio a {
  font-weight: 900;
  color: #0c1547;
  text-decoration: none;
}

#page-inicio .app-header-verde {
  height: 42px;
}

#page-inicio .app-main {
  padding-top: 42px;
  min-height: calc(100% - 98px);
  text-align: center;
  color: #0c1547;
}

#inicio-title {
  color: white;
  font-size: 52px;
  font-weight: 700;
}

#page-inicio .app-main-container {
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 15px;
}

.inicio-text-box {
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inicio-text {
  margin-bottom: 10px;
}

.inicio-link.btn {
  text-decoration: none;
  color: inherit;
}

.inicio-link-box {
  padding: 20px 0;
  width: fit-content;
  margin: 0 auto;
}

.btn-inicio {
  background-color: #0c1547;
  color: #fff;
  border-radius: 30px;
  width: fit-content;
  padding: 15px;
  text-align: center;

  & > .span-next {
    margin-right: 10px;
    text-transform: uppercase;
    font-size: 18px;
  }

  & > img {
    transform: rotate(270deg);
  }
}

.image-cuidados-box {
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  #page-inicio .app-main-container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .inicio-text-box {
    padding: 0;
  }

  .CookieConsent {
    font-size: 12px !important;
  }

  .btn-inicio {
    padding: 15px 10px;
  }
}

@media only screen and (max-width: 551px) {
  .CookieConsent > div {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-height: 591px) {
  .inicio-text-box {
    padding: 0;
  }
}

.footer-inicio {
  padding: 20px 10px;
  text-align: center;
  font-size: 12px;
}

.span-bold {
  font-weight: 900;
}

.inicio-text-pregunta {
  font-size: 21px;
  font-weight: 900;
}
