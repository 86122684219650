@font-face {
  font-family: "FuturaHeavy";
  src: url("fonts/FuturaHeavy.otf") format("opentype");
}

@font-face {
  font-family: "clockicons";
  src: url("fonts/clockicons.eot"); /* IE9*/
  src: 
  url("fonts/clockicons.woff2") format("woff2"), /* chrome、firefox */
  url("fonts/clockicons.woff") format("woff"), /* chrome、firefox */
  url("fonts/clockicons.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("fonts/clockicons.svg#clockicons") format("svg"); /* iOS 4.1- */
}

body {
  margin: 0;
  height: 100vh;
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: 'Roboto', sans-serif, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
