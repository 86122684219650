.image-export-body {
  max-width: 570px;
  margin: 0 auto;
  position: absolute;
  z-index: -1;
  top: 0;
}

.image-export-box {
  background-color: #fcfceb;
  padding: 15px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titulo-imagen-box {
  display: flex;
  text-transform: uppercase;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.titulo-imagen {
  font-size: 42px;
  font-weight: 900;
  text-align: left;
  width: 220px;
}

.grafica-image-box {
  width: 100%;
}

.frases-trabajo {
  font-size: 22px;
  padding: 5px 20px;
}
.frase-tareas {
  font-size: 36px;
  font-weight: 900;
  padding: 5px 20px;
}

.span-negrita {
  font-weight: 900;
}

@media all and (max-width: 767px) {
  .titulo-imagen {
    font-size: 32px;
    width: 170px;
  }

  .titulo-imagen-box > img {
    width: 100px;
  }

  .frase-tareas {
    font-size: 32px;
  }

  .frases-trabajo {
    font-size: 18px;
  }
}
