* {
  margin: 0;
  padding: 0;
}

#App {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #0c1547;
}

.app-header {
  background-color: #f47b20;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.app-header-verde {
  background-color: #62b997;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.header-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: right;
  padding: 15px 10px 15px 45px;
  padding: 13px 15px;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-menu-wrap {
  width: 215px !important;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 1) !important;
  outline: none;
  transition: color 0.2s;
  text-align: center;
}

.bm-item:hover,
.link-activo {
  color: rgba(255, 255, 255, 1);
}

.bm-burger-button {
  position: fixed;
  width: 23px;
  height: 23px;
  left: 11px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: rgba(255, 255, 255, 1);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: rgba(255, 255, 255, 1);
  width: 5px !important;
  height: 25px !important;
}

#react-burger-cross-btn {
  top: 8px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #f49b25;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
