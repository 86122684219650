#page-resultados {
  min-height: 100%;
  background-color: #fcfceb;
  z-index: 1;
}

#page-resultados a {
  font-weight: 900;
  color: #0c1547;
  text-decoration: none;
}

#page-resultados .app-main {
  padding-top: 43px;
  height: 100%;
  background-color: #fcfceb;
}

#page-resultados .app-main-container {
  max-width: 550px;
  margin: 0 auto;
  padding: 15px 15px 5px 15px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #0c1547;
}

#page-resultados h2 {
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 30px;
}

#titulo-grafica-1 {
  margin-bottom: 30px;
}

.highchart-box {
  margin: 30px auto;
  margin-bottom: 40px;
  & * {
    font-family: "Roboto", sans-serif !important;
  }
}

.iceberg-box {
  margin: 50px auto;
  background-color: #f5e23e;

  & h2 {
    margin: 0 !important;
    padding: 30px;
    padding-bottom: 0;
  }

  & .iceberg-parrafo {
    padding: 30px;
    padding-bottom: 70px;
    font-size: 21px;
    line-height: 32px;
  }
}

.grafica-parrafo,
.calculadora-parrafo {
  margin: 15px auto;
}

.calculo-box {
  margin: 50px auto;
}

#dinero-parrafo {
  font-size: 24px;
  font-weight: 900;
}

#empleados-parrafo {
  font-size: 24px;
  font-weight: 900;
}

.calculadora-container {
  margin: 10px auto;
  max-width: 260px;
}

.calculadora {
  margin: 0 auto;
  padding: 20px;
  background-color: #b220dfc4;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
}

.resultado-box {
  border-radius: 10px;
  font-size: 25px;
  background-color: rgb(198, 216, 198);
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.botones-box {
  padding: 5px;
  width: fit-content;
  margin: 10px auto;
}

.botones-box:last-child {
  padding-bottom: 0;
}

.boton-cal {
  border-radius: 10px;
  background-color: rgb(207, 200, 200);
  height: auto;
  width: 50px;
  padding: 5px;
  font-weight: 800;
  font-size: 20px;
  margin: 2px 4px;
}

.boton-cal:first-child {
  margin-left: 0px;
}

.boton-cal:last-child {
  margin-right: 0px;
}

.boton-resultado {
  border-radius: 25px;
  background-color: #0c1547;
  color: white;
  width: 100%;
  padding: 15px 25px;
  margin: 2px 0;
  outline: none;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    margin-left: 5px;
  }
}

.compartir-box {
  margin: 20px auto;
  margin-bottom: 5px;
}

.resultados-link.btn {
  width: 100%;
  background: none;
  border: none;
  text-decoration: none;
  color: inherit;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}

.div-btn-resultados {
  border-radius: 30px;
  max-width: 320px;
  margin: 30px auto;
  padding: 15px 10px;
  text-align: center;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.span-legend {
  width: 1px;
  background-color: purple;
  color: transparent;
  margin-right: 5px;
  padding: 0px 10px;
}

.div-btn-resultados > .span-next {
  margin-right: 5px;
}

.div-btn-descarga {
  border-radius: 30px;
  max-width: 320px;
  margin: 30px auto;
  padding: 15px 10px;
  text-align: center;
  display: flex;
  align-items: normal;
  justify-content: center;
}

.div-btn-descarga > .span-descarga {
  margin-right: 10px;
}

.descarga {
  background-color: #0c1547;
  color: white;
}

.mas-info {
  background-color: #62b997;
  color: white;
}

.share-btn-box {
  margin: 10px auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.share-btn-box img {
  width: 49px;
  height: 49px;
}

.footer-resultados {
  padding: 20px 10px;
  text-align: center;
  font-size: 12px;
}

.logos-empresas-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px auto;
  margin-bottom: 0;
}

.logo-emp-box {
  padding: 12px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-emp-box > img {
  width: 137px;
  mix-blend-mode: multiply;
}

.encuesta > img {
  transform: rotate(90deg);
  align-self: baseline;
}

.mas-info > img {
  transform: rotate(270deg);
  align-self: baseline;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .div-btn-resultados > .span-next {
    margin-right: 1px;
  }

  .div-btn-descarga > .span-descarga {
    margin-right: 6px;
  }

  .div-btn-resultados {
    padding: 15px 0px;
  }

  .div-btn-descarga {
    padding: 15px 5px;
  }

  #page-resultados .app-main-container {
    padding: 0;

    & > *:not(.iceberg-box) {
      padding: 0 15px;
    }
  }
}

.hidden {
  display: none;
}
