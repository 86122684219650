#page-avisolegal {
  height: 100%;
  font-weight: 500;
  background-color: #fcfceb;
}

#page-avisolegal a {
  font-weight: 900;
  color: #0c1547;
  text-decoration: none;
}

#page-avisolegal .app-main {
  min-height: calc(100% - 102px);
  padding-top: 43px;
  background-color: #fcfceb;
}

#page-avisolegal .app-main-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
  padding-top: 15px;
  background-color: #fcfceb;
}

#page-avisolegal .title-about {
  font-weight: 900;
  font-size: 24px;
  margin: 30px 0;
}

#page-avisolegal .app {
  &__toggle {
    width: 100%;
    margin: 1rem 0;
    margin-bottom: 0;
    border: 1px solid #e2dddd;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    background-color: #e5e5e5;
    transition: background-color 300ms;
    outline: 0 none;
    &--active {
      background-color: #e5e5e5;
    }
  }

  &__toggle-title {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-right: 3px;
    text-align: left;
  }

  &__button {
    display: block;
    width: 7rem;
    font-weight: bold;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    padding: 0.5rem;
    border-radius: 3px;
    background-color: #e5e5e5;
  }
}

#page-avisolegal button {
  font-size: inherit;
  box-shadow: none;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 1px solid #e2dddd;
  margin-top: 10px;
}

.icon {
  width: 1em;
  height: 1em;
  transition: transform 300ms cubic-bezier(0, 1, 0, 1);
  will-change: transform;
  &--expanded {
    transform: rotateZ(-179.99deg);
  }
}

.rotate90 {
  transform: rotateZ(90deg);
}

.accordion-content {
  padding: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

h4.parrafo-accordion {
  font-weight: 900;
}

.parrafo-accordion {
  margin: 15px 0;
}

.footer-avisolegal {
  padding: 20px 10px;
  text-align: center;
  font-size: 12px;
  background-color: #fcfceb;
}

#page-avisolegal .pregunta {
  font-weight: bold;
}
