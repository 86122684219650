#page-masinfo {
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  background-color: #fcfceb;
}

#page-masinfo a {
  font-weight: 700;
  color: #0c1547;
  text-decoration: none;
}

#page-masinfo .title-masinfo {
  font-size: 24px;
  font-weight: 900;
  margin: 30px 0;
}

.masinfo-ul-title {
  font-size: 18px;
  font-weight: 900;
  margin: 15px 0;
}

.masinfo-lista {
  padding-left: 30px;
}

.masinfo-lista > li {
  margin: 5px 0;
}

#page-masinfo .app-main {
  padding-top: 43px;
  min-height: calc(100% - 150px);
  background-color: #fcfceb;
}

#page-masinfo .app-main-container {
  max-width: fit-content;
  margin: 0 auto;
  padding: 15px;
}

.masinfo-content {
  padding: 15px;
  line-height: 150%;
}

.footer-masinfo {
  background-color: #fcfceb;
  padding: 20px 10px;
  text-align: center;
  font-size: 12px;

  & a {
    font-weight: 900 !important;
  }
}
