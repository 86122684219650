#page-about {
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  background-color: #fcfceb;
}

#page-about a {
  font-weight: 900;
  color: #0c1547;
  text-decoration: none;
}

#page-about .title-about {
  font-size: 24px;
  font-weight: 900;
  margin: 30px 0;
}

#page-about .app-main {
  padding-top: 43px;
  min-height: calc(100% - 102px);
  background-color: #fcfceb;
}

#page-about .app-main-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
}

#page-about .logo-emp-box {
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;

  & > img {
    width: 240px;
    mix-blend-mode: multiply;
  }
}

.about-content {
  padding: 15px;
  line-height: 150%;
}

.footer-about {
  padding: 20px 10px;
  text-align: center;
  font-size: 12px;
  background-color: #fcfceb;
}

#page-about .masinfo-text {
  margin: 12px 0;
}
