.input-number-box {
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  width: fit-content;
  border-radius: 10px;
  margin: 0 auto;
  padding: 15px 10px;
}

.input-number-box > input[type="number"] {
  font-size: 36px;
  text-align: right;
  border: none;
  outline: none;
  font-weight: 900;
  width: 47px;
  color: #0c1547;
  background-color: #fcfceb;
}

.input-number-box > span {
  font-size: 36px;
  font-weight: 900;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.image-horas-box {
  width: 100%;
  margin: 0 auto;
  box-sizing: content-box;
}

.imagen-grande {
  width: 65%;
  margin: 0 auto;
  & > img {
    filter: brightness(1.1);
    mix-blend-mode: multiply;
  }
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* BARRA */

#progressBarContainer {
  position: fixed;
  z-index: 0 !important;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 42.6px;
  left: 0;
}

#progressBar {
  background: linear-gradient(to left, #0c1547, rgb(14, 64, 230));
  transform-origin: top left;
  transform: scale(0, 0);
  opacity: 0;
}

#progressBarContainer,
#progressBar {
  height: 10px;
}

/* FIN BARRA */

#page-encuesta {
  min-height: 100%;
  background-color: #fcfceb;
}

#page-encuesta .app-main {
  padding-top: 43px;
  background-color: #fcfceb;
}

#page-encuesta .app-main-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 0px 15px;
  font-family: "Roboto", sans-serif;
}

#page-encuesta .section-box {
  padding-top: 65px;
}

#page-encuesta .encuesta-text-box {
  padding: 10px 0;
}

#page-encuesta .pregunta-horas {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 10px;
}

#page-encuesta .semana-span {
  text-transform: uppercase;
  font-weight: 900;
}

#page-encuesta .btn-encuesta-box {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

#page-encuesta .encuesta-link.btn {
  text-decoration: none;
  color: inherit;
}

#page-encuesta .encuesta-button {
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  margin: 10px auto;
}

#page-encuesta .div-btn-encuesta {
  background-color: #0c1547;
  color: white;
  border-radius: 30px;
  margin: 50px auto;
  margin-bottom: 10px;
  width: 245px;
  padding: 15px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .span-next {
    margin-right: 10px;
    text-transform: uppercase;
    font-size: 18px;
  }

  & > .span-goback {
    margin-left: 5px;
  }

  &.volver {
    width: 152px;
  }

  &.volver > img {
    transform: rotate(90deg);
  }
}

.div-button-encuesta {
  font-family: inherit;
  background-color: #0c1547;
  color: white;
  border-radius: 30px;
  width: 245px;
  padding: 15px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .span-next {
    font-weight: 500;
    font-size: 18px;
    margin-right: 10px;
    text-transform: uppercase;
  }

  & > img {
    transform: rotate(270deg);
  }
}

.encuesta-text-box > input {
  width: 100%;
  padding: 2px;
}

.encuesta-text.pregunta {
  font-size: 16px;
  font-weight: 500;
}

.pregunta-horas {
  text-align: center;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  #page-encuesta {
    background-color: #fcfceb;

    & .app-main-container {
      background-color: #fcfceb;
      height: 100%;
      padding: 0;
    }
    & .section-box {
      
      height: 100vh;
      padding: 0 10px;
      background-color: #fcfceb;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:not(:first-child) {
        padding-top: 5px;
      }
    }

    & #pregunta-1-box .encuesta-text-box.pregunta-horas:first-child {
      padding-top: 0;

      & > .encuesta-text {
        padding-top: 0;
      }
    }

    & .time-duo-box {
      padding: 0 15px;
    }

    & .encuesta-text {
      margin-bottom: 0px;
      padding: 3px 0;
    }
  }
}

@media only screen and (max-width: 374px) and (orientation: portrait) {
  #page-encuesta {
    background-color: #fcfceb;
    & .section-box {
      &:not(:first-child) {
        padding-top: 20px;
      }
    }
  }
}

.encuesta-link-box {
  width: fit-content;
  margin: 0 auto;
}

.encuesta-link-box.btn-continuar-box {
  margin-top: 10px;
}

input[type="text"]::placeholder {
  text-align: center;
  color: #c6cbcf;
  font-family: "Roboto", sans-serif;
}

/* MODAL */

.button-modal {
  background: #247ba0;
  color: #fff;
}

button.button-close-modal {
  border: none;
  background-color: transparent;
  vertical-align: middle;
  margin-left: 0.5rem;
  outline: none;
}
