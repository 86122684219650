#page-sobre-ti {
  min-height: 100%;
  background-color: #fcfceb;
  display: flex;
  flex-direction: column;
}

#page-sobre-ti a {
  font-weight: 900;
  color: #0c1547;
  text-decoration: none;
}

#page-sobre-ti .app-main {
  padding-top: 43px;
  height: calc(100% - 102px);
  background-color: #fcfceb;
}

#page-sobre-ti .app-main-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 10px;
  font-family: "Roboto", sans-serif;
  background-color: #fcfceb;
}

#page-sobre-ti .preguntas-personales-box {
  color: #0c1547;
  padding-top: 30px;
  font-size: 18px;
  font-weight: 900;
}

#page-sobre-ti .encuesta-text-box {
  padding: 10px 15px;
}

#page-sobre-ti .btn-encuesta-box {
  display: flex;
  justify-content: center;
  margin: 15px auto;
}

#page-sobre-ti .encuesta-link.btn {
  text-decoration: none;
  color: inherit;
}

#page-sobre-ti .encuesta-button {
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
}

#page-sobre-ti .div-btn-encuesta {
  background-color: #0c1547;
  border-radius: 30px;
  margin: 20px auto;
  width: 270px;
  padding: 15px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  & .span-goback {
    color: white;
    font-weight: 500;
    margin-left: 10px;
    text-transform: uppercase;
  }
  & > img {
    transform: rotate(90deg);
  }
}

#page-sobre-ti .div-button-resultados {
  font-family: inherit;
  font-size: 18px;
  background-color: #62b997;
  color: white;
  border-radius: 30px;
  width: 270px;
  padding: 15px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .span-next {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
    text-transform: uppercase;
  }

  & > img {
    transform: rotate(270deg);
  }
}

#page-sobre-ti .encuesta-text-box > input {
  width: 100%;
  padding-bottom: 3px;
  background-color: #fcfceb;
}

#page-sobre-ti .encuesta-label {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

#page-sobre-ti label.label-check {
  font-size: 12px;
  font-weight: 500;
}

#page-sobre-ti .hidden {
  display: none !important;
}

#page-sobre-ti .checkbox {
  display: flex;
}

#page-sobre-ti .checkbox > input {
  width: fit-content;
  margin-right: 5px;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  #page-sobre-ti .app-main-container {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & .last-checkbox {
      padding-bottom: 0;
    }
  }
}

#page-sobre-ti .encuesta-link-box {
  width: fit-content;
  margin: 0 auto;
}

#preguntas-personales-box input:focus,
#preguntas-personales-box input:active {
  pointer-events: auto !important;
}

#preguntas-personales-box input[type="text"] {
  border: none;
  border-bottom: 2px solid #0c1547;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &::placeholder {
    text-align: left;
  }

  &::-ms-input-placeholder {
    text-align: left;
  }

  &::-moz-placeholder {
    text-align: left;
  }
  
  &::-webkit-input-placeholder {
    text-align: left;
  }
}

.footer-sobre-ti {
  padding: 20px 10px;
  text-align: center;
  font-size: 12px;
  background-color: #fcfceb;
  color: #0c1547;
}

.btn-modal-privacy {
  color: #0c1547;
  font-family: inherit;
  font-weight: 900;
  font-size: inherit;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
}
